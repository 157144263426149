<template>
  <div class="flex flex-col min-h-screen" dir="ltr">
    <DismissableHeader
      @onCancle="() => (showHeader = false)"
      v-if="showHeader"
      :currentLocale="globalStore.siteCurrentLocale"
    />

    <DefaultHeader />

    <main class="mb-auto">
      <div class="w-full px-4 md:px-0 md:mx-auto md:w-[1024px]">
        <TransitionGroup name="list" tag="div">
          <CustomNotification
            v-for="(bannerNotification, index) in bannerNotifications?.data"
            :key="index"
            :data="bannerNotification"
            :index="index"
          />
        </TransitionGroup>
      </div>
      <slot class="my-4"></slot>
      <CommonLandbotChat v-if="globalStore.siteSettings.landbot_chat"/>
    </main>
    <ConditionalFooter />

    <DefaultFooter />
  </div>
</template>

<script setup>
import { useGlobalStore } from "~/stores/global";
const getCookie = useCookie("counterUser").value;
const hideSiteSwitchBanner = useCookie("hideSiteSwitchBanner").value;
const globalStore = useGlobalStore();
const route = useRoute();
const { locale } = useI18n();
const showHeader = ref(route.query.preview || getCookie != null);
if (hideSiteSwitchBanner) {
  showHeader.value = false;
}

const { data: bannerNotifications, pending } = await useBannerNotifications();
addAnalytics(globalStore.siteSettings.google_analytics_4_id);
addGTMAnalytics(globalStore.siteSettings.google_tag_manager_id);

onNuxtReady(() => {
  addCookiebot(globalStore.siteSettings.cookiebot_notice, globalStore.siteSettings.cookiebot_id);
});

</script>

<style scoped>
/* apply transition to moving elements */
.list-enter-active,
.list-move,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: flex;
  transform: translateY(-60px);
}
</style>
